// @ts-nocheck
import $ from 'jquery';

export default class RoutingNumberValidator {
  constructor(routingNumber) {
    if (typeof routingNumber === 'number') {
      //.match() will error on numbers
      this._routingNumber = routingNumber.toString();
    } else {
      this._routingNumber = routingNumber;
    }
  }

  validate() {
    const routingNumber = this._routingNumber;

    if (!routingNumber.match(/^[\d]{9}$/)) {
      return 'must be exactly 9 numbers';
    }

    // validate checksum
    const part1 = (parseInt(routingNumber[0]) + parseInt(routingNumber[3]) + parseInt(routingNumber[6])) * 3;
    const part2 = (parseInt(routingNumber[1]) + parseInt(routingNumber[4]) + parseInt(routingNumber[7])) * 7;
    const part3 = parseInt(routingNumber[2]) + parseInt(routingNumber[5]);
    const checkdigit = ((10 - ((part1 + part2 + part3) % 10)) % 10).toString();

    // validate routing symbol
    const number = parseInt(routingNumber[0] + routingNumber[1]);
    const validateRoutingSymbol =
      (number >= 0 && number <= 12) ||
      (number >= 21 && number <= 32) ||
      (number >= 61 && number <= 72) ||
      number === 80;

    if (checkdigit !== routingNumber[8] || routingNumber === '000000000' || !validateRoutingSymbol) {
      return 'is not a valid routing number';
    }

    const accountNumber = $('#forms_account_info_form_account_number').val();
    if (accountNumber === routingNumber) {
      return "can't be the same as the account number";
    }

    return null;
  }
}
