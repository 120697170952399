import $ from 'jquery';
import ShowableWidget from 'gears/ui/showable-widget/component';

let singleton = undefined;

class Waiting extends ShowableWidget {

  constructor(options) {
    super(...arguments);

    // The constructor should return the singleton instance if it exists
    if (singleton instanceof Waiting && singleton.state() !== "destroyed") {
      return singleton.set(options);
    }

    singleton = this;
  }

  _update() {
    return this._render();
  }

  _render() {
    if (this.$el) {
      if (this.changedOptions.title) {
        return this.$el.find(`.${Waiting.className}__title`).html(this.options.title);
      }
    } else {
      return this.$el = $(`<div class=\"${Waiting.className}\" />`)
        .data(this._getNamespace(), this)
        .append(`\
<div class="${Waiting.className}__dialog">
<div class="${Waiting.className}__title">${this.options.title}</div>
<div class="${Waiting.className}__indicator"></div>
</div>\
`);
    }
  }
}

Waiting.defaults = {
  animate: true,
  destroyOnHidden: true,
  title: "Please Wait",
  removeOnHidden: true
};

Waiting.className = "waiting";

Waiting.namespace = "gears:waiting";

export default Waiting;
