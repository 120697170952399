import _ from 'lodash';
import $ from 'jquery';
import dataset from 'gears/util/dataset/component';

//  Anatomy of a Gears Widget
//  -------------------------
//  @$el: the element created by the widget; it usually contains a class with the same name
//  @$target: (optional) an existing DOM element that widget is instantiated with. Usually the element the user interacts with that acts on @$el; e.g. a link ($target) the user clicks on to show a tooltip ($el)
//  @options: the current option for the widget
//  @previousOptions: the options for the widget prior to the most recent invocation of .set()
//  @changedOptions: the difference between @options and @previousOptions

class Widget {

  constructor(target, options) {

    // target is optional, if an element or selector isn't found
    // assume it's an options object
    if (target && (target.nodeType || (typeof target === 'string') || target instanceof $)) {
      this.$target = $(target).first();
      // if @$target already has an instanced store in the data cache,
      // return it instead of creating a new instance
      let instance = this.$target.data(this._getNamespace());
      if (instance instanceof Widget) {
        return instance.set(options);
      } else {
        this.$target.data(this._getNamespace(), this);
      }
    } else {
      options = target;
    }

    // Give each widget a unique ID
    this.wid = _.uniqueId();

    // Initialize all the options objects
    this.previousOptions = {};
    this.changedOptions = {};
    this.options =  this._fetchOptions(options);

    this._initialize();
  }


  set(options) {
    if (_.isEmpty(options)) { return this; }
    this.previousOptions = this.options;
    this.options = _.extend({}, this.options, options);
    this.changedOptions = {};
    for (let key of Object.keys(this.options || {})) {
      let value = this.options[key];
      if (this.previousOptions[key] !== value) { this.changedOptions[key] = value; }
    }
    if (!_.isEmpty(this.changedOptions)) { this._update(); }
    return this;
  }

  destroy() {
    __guard__(this.$target, x => x.removeData(this._getNamespace()));
    this.$target = null;
    this.$el = null;
    this.options = null;
    this.previousOptions = null;
    this.changedOptions = null;
    return this;
  }

  // invoked when a new widget is first instantiated
  _initialize() {}


  // invoked when calling set and the widget's options have been changed
  _update() {}

  _getNamespace() { return this.constructor.namespace; }

  _getDefaults() { return this.constructor.defaults; }

  _fetchOptions(options) {
    let defaults = this._getDefaults();
    if (this.$target) {
      let dataOptions = dataset(
        this.$target,
        this._getNamespace()
          .substr(this._getNamespace().lastIndexOf(":")+1)
          .replace(/\:/g, "-")
      );
      return _.extend({}, defaults, dataOptions, options);
    } else {
      return _.extend({}, defaults, options);
    }
  }
}

Widget.namespace = 'gears:widget';

export default Widget;

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
