// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';
import { createForAttachments, createForOlderPackets } from 'components/expandable_section';
import FileDownloadLinkListener from 'components/file_download_link_listener';

export default class IndexView extends BaseView {
  constructor() {
    super();
    $('body')
      .on('click', '.attachment-toggle', this.toggleAttachments)
      .on('click', '.toggle-older-packets', this.toggleOlderPackets);

    this.fileDownloadLinkSetUp();
    this.markPageLoaded();
  }

  destroy() {
    $('body')
      .off('click', '.attachment-toggle', this.toggleAttachments)
      .off('click', '.toggle-older-packets', this.toggleOlderPackets);
  }

  toggleAttachments(event) {
    createForAttachments($(event.currentTarget)).toggle();
  }

  toggleOlderPackets(event) {
    event.preventDefault();
    createForOlderPackets($(event.currentTarget)).toggle();
  }

  fileDownloadLinkSetUp() {
    const triggers = ['.js-download-full-packet', '.js-owner-packet', '.js-owner-packet-attachment'];
    new FileDownloadLinkListener('body', triggers);
  }
}
