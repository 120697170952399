// @ts-nocheck
import $ from 'jquery';

export default class ExpandableSection {
  constructor(
    $list,
    $listItems,
    $showHideDescriptions,
    $icon,
    showIconClass,
    hideIconClass,
    firstListItemSelector,
    $ariaExpandedElement,
    fetchUrl,
  ) {
    this._$list = $list;
    this._$listItems = $listItems;
    this._showHideDescriptions = $showHideDescriptions;
    this._showDescription = this._showHideDescriptions !== null ? $($showHideDescriptions.first()) : null;
    this._$icon = $icon;
    this._showIconClass = showIconClass;
    this._hideIconClass = hideIconClass;
    this._firstListItemSelector = firstListItemSelector;
    this._$ariaExpandedElement = $ariaExpandedElement;
    this._fetchUrl = fetchUrl;
  }

  toggle() {
    if (this._$listItems.length === 0) {
      this._fetchListItems(() => {
        this._toggleVisibility();
      });
    } else {
      this._toggleVisibility();
    }
  }

  _fetchListItems(onSuccess) {
    if (this._fetchUrl != null) {
      $.get(this._fetchUrl, onSuccess);
    }
  }

  _toggleVisibility() {
    if (this._showIconClass && this._hideIconClass) {
      this._toggleIcon();
    }
    this._$list.toggle();
    if (this._showHideDescriptions !== null) {
      this._showHideDescriptions.toggle();
      if (this._showDescription !== null && this._showDescription.is(':visible')) {
        this._showDescription.focus();
      } else {
        this._$list.find(this._firstListItemSelector).focus();
      }
    }
    if (this._$ariaExpandedElement !== null) {
      const currentValue = this._$ariaExpandedElement.attr('aria-expanded');
      const newValue = currentValue === 'true' ? 'false' : 'true';
      this._$ariaExpandedElement.attr('aria-expanded', newValue);
    }
  }

  _toggleIcon() {
    this._$icon.toggleClass(this._showIconClass);
    this._$icon.toggleClass(this._hideIconClass);
  }
}

export function createForAttachments($attachmentsToggle) {
  const $attachments = $attachmentsToggle.parents('.attachments');
  return new ExpandableSection(
    $attachments.find('.attachments__list'),
    $attachments.find('.attachments__list li'),
    null,
    $attachmentsToggle.find('.fa-solid'),
    'fa-chevron-down',
    'fa-chevron-up',
    '',
    null,
    $attachmentsToggle.data('href'),
  );
}

export function createForOlderPackets($olderPacketsToggle) {
  const $olderPackets = $olderPacketsToggle.parent().find('.owner-panel__older-packets-container');

  return new ExpandableSection(
    $olderPackets,
    $olderPackets.find('.packet'),
    $olderPacketsToggle.parent().find('.js-show-label,.js-hide-label'),
    $olderPacketsToggle.find('.fa-solid'),
    'fa-chevron-down',
    'fa-chevron-up',
    '.packet-name:first',
    $olderPacketsToggle.find('[aria-expanded]'),
    $olderPacketsToggle.data('ajax-path'),
  );
}
