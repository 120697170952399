import _ from 'lodash';
import $ from 'jquery';

// Polyfil for HTML5 dataset property
// An attempt will be made to convert booleans, null, numbers, and JSON to native JavaScript types
// Optionally, you may pass a namespace to filter the set of results

function dataset(el, namespace) {

  var originalDataset = {};
  var parsedDataset = {};
  var dataAttrRegex = /^data\-(.+)$/;
  var rbrace = /^(?:\{.*\}|\[.*\])$/; // taken from jQuery
  var key;

  // Try to convert string values to a JavaScript type or object
  var convertType = function(data) {
    if (typeof data === "string") {
      try {
        data =
          data === "true" ? true :
          data === "false" ? false :
          data === "null" ? null :
          // Only convert to a number if it doesn't change the string
          data === (+data + "") ? +data :
          rbrace.test(data) ? $.parseJSON(data) :
          data;
      } catch (e) {}
    }
    return data;
  };

  // get the actual DOM node
  el = $(el)[0];

  // Use the native HTML5 data if it exists
  if (el.dataset) {
     // convert the DOMStringMap to an object since DOMStringMap doesn't allow for nested
     _.extend(originalDataset, el.dataset);
  } else {
    $.each(el.attributes, function(index, attr) {
      if (dataAttrRegex.test(attr.nodeName)) {
        var key = $.camelCase(attr.nodeName.match(dataAttrRegex)[1]);
        originalDataset[key] = attr.nodeValue;
      }
    });
  }

  if (namespace) {

    // we expect the namespace to be camelCased
    namespace = $.camelCase(namespace);

    for (key in originalDataset) {
      if (!originalDataset.hasOwnProperty(key)) continue;

      // If there's a data attribute that matches the namespace exactly, assume
      // it's all we want and just set that value. Don't support cases like:
      // <div data-namespace='{"foo":"bar", "bar":"foo"}' data-namespace-extra="stuff" />
      if (key === namespace) {
        parsedDataset = convertType(originalDataset[key])
        break;
      }

      if (key.indexOf(namespace) === 0) {
        var newKey = key.replace(new RegExp("^" + namespace + "([A-Z])"), function() {
          return arguments[1].toLowerCase();
        });
        parsedDataset[newKey] = convertType(originalDataset[key]);
      }
    }

  } else {

    for (key in originalDataset) {
      if (!originalDataset.hasOwnProperty(key)) continue;
      parsedDataset[key] = convertType(originalDataset[key]);
    }

  }

  return parsedDataset;
}

export default dataset;
