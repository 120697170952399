// @ts-nocheck
import $ from 'jquery';

const DELIVERY_OPTIONS_SIZE = 3;

export default class TenNinetyNineSettingForm {
  constructor($checkbox, defaultCheckedValue, $dropdown, defaultDropdownValue) {
    this._$checkbox = $checkbox;
    this._defaultCheckedValue = defaultCheckedValue;
    this._$dropdown = $dropdown;
    this._defaultDropdownValue = defaultDropdownValue;
  }

  setupPreferenceDropdownForReady() {
    if (this._defaultCheckedValue) {
      const options = this._$dropdown.find('option');
      if (options.length < DELIVERY_OPTIONS_SIZE) {
        this._$dropdown.append(new Option('Electronic', 'Electronic'));
      }
    }
    this._$dropdown.find(`option[value="${this._defaultDropdownValue}"]`).prop('selected', true);
  }

  consentChange(defaultDropdownValue, defaultCheckedValue, currentValue) {
    const $dropdown = $('.js-ten-ninety-nine-preference-dropdown');
    this.setupPreferenceDropdownForChange($dropdown, defaultDropdownValue, defaultCheckedValue, currentValue);
  }

  setupPreferenceDropdownForChange($dropdown, defaultDropdownValue, defaultCheckedValue, consentChecked) {
    const options = $dropdown.find('option');
    if (defaultCheckedValue) {
      if (consentChecked) {
        if (options.length < DELIVERY_OPTIONS_SIZE) {
          $dropdown.append(new Option('Electronic', 'Electronic'));
        }
        $dropdown.find(`option[value="${defaultDropdownValue}"]`).prop('selected', true);
      } else {
        $dropdown.find("option[value='Electronic']").remove();
        $dropdown.find("option[value='Paper & Electronic']").prop('selected', true);
      }
    } else if (consentChecked) {
      if (options.length < DELIVERY_OPTIONS_SIZE) {
        $dropdown.append(new Option('Electronic', 'Electronic'));
      }
      $dropdown.find("option[value='Electronic']").prop('selected', true);
    } else {
      $dropdown.find("option[value='Electronic']").remove();
      $dropdown.find(`option[value="${defaultDropdownValue}"]`).prop('selected', true);
    }
  }
}

export function setUpCheckboxAndDropdown() {
  const $consentCheckbox = $('.js-ten-ninety-nine-consent-checkbox');
  const defaultConsentValue = $consentCheckbox[0].checked;
  const $preferenceDropdown = $('.js-ten-ninety-nine-preference-dropdown');
  const defaultDropdownValue = $('.js-ten-ninety-nine-preference-dropdown :selected').text();

  if (!defaultConsentValue) {
    $preferenceDropdown.find("option[value='Electronic']").remove();
  }

  return new TenNinetyNineSettingForm($consentCheckbox, defaultConsentValue, $preferenceDropdown, defaultDropdownValue);
}
