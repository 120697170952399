// @ts-nocheck
import $ from 'jquery';
import Cookies from 'js-cookie';
import Waiting from 'gears/ui/waiting/component';

export default class FileDownloadLinkListener {
  constructor(container, triggers) {
    this.$container = $(container);

    this.$container.on('click', triggers.join(', '), () => {
      this.waiting = new Waiting();
      this.waiting.show();
      this.waitForFileDownload();
    });
  }

  waitForFileDownload = () => {
    const cookieVal = Cookies.get('file_download');
    if (cookieVal === undefined) {
      setTimeout(this.waitForFileDownload, 1000);
    } else {
      Cookies.remove('file_download');
      this.waiting.hide();
    }
  };
}
