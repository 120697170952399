// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';
import ClientSideValidations from 'rails.validations';

export default class NewView extends BaseView {
  constructor() {
    super();
    this._$form = $('.js-contribution-select-form');
    ClientSideValidations.callbacks.form.pass = this._setFormUrl;
    this.replaceAmountCommas();
    this.markPageLoaded();
  }

  _setFormUrl($form) {
    const actionBase = $form.attr('action');
    const contributionMethod = $form.find('.js-contribution-method').val();
    const anchor = 'owner_contributions';

    let contributionAction;
    if (contributionMethod === 'ach') {
      contributionAction = 'account_info';
    } else if (contributionMethod === 'cc') {
      contributionAction = 'cc_account_info';
    }

    const contributionUrl = [actionBase, contributionAction].join('/');
    const contributionUrlWithAnchor = [contributionUrl, anchor].join('#');

    $form.attr('action', contributionUrlWithAnchor);
  }

  replaceAmountCommas = () => {
    const $input = $('.js-contribution-amount');
    $input.on('change', () => $input.val($input.val().replace(/,/g, '')));
  };
}
