import _ from 'lodash';
import $ from 'jquery';
import transitionend from 'gears/support/transitionend/component';
import Widget from 'gears/ui/widget/component';

// This is a special event that can be added to widget elements
// to determine when a widget is removed from the DOM.
// Since jQuery removes all events and data before removing DOM
// elements, listening for the removal of this event will tell
// us that the bound element is about to be removed
// Note: that means that this event should never be manually
// removed or the callback will also be invoked.
$.event.special["gears:showablewidget:remove"] = {remove(obj) { return __guardMethod__(obj, 'handler', o => o.handler()); }};


class ShowableWidget extends Widget {

  state() {
    return this._state;
  }

  show(callback) {

    if (this._state === "shown") {
      __guardFunc__(callback, f => f());
      return this;
    }

    if (callback) {
      this._showCallbacks.add(callback);
    }

    // make sure the callback is added (above) before doing this check
    if (this._state === "showing") {
      return this;
    }

    // make sure @$el is rendered, but don't rerender if it already exists
    if (!this.$el) { this._render(); }

    this._state = 'showing';

    // determine if $el is already in the DOM, if not add it
    if (!this.$el.closest("body").length) { this.$el.appendTo("body"); }

    if (this.options.animate && transitionend) {

      this.$el
        .removeClass('u-hidden is-transitioning-out') // just in case
        .addClass('is-before-transition');

      (this.$target || this.$el).trigger(this._getNamespace() + ':show');

      // force a repaint to ensure transitioning works
      this._position();

      // start the transition
      this.$el
        .addClass('is-transitioning-in')
        .removeClass('is-before-transition')
        .off(transitionend + "." + this._getNamespace())
        .one(transitionend + "." + this._getNamespace(), _.bind( function() {
          this.$el.removeClass('is-transitioning-in');
          return this._onShown();
        }
        , this));
    } else {
      this.$el.removeClass('u-hidden');
      (this.$target || this.$el).trigger(this._getNamespace() + ':show');
      this._position();
      this._onShown();
    }
    return this;
  }

  hide(callback) {

    // argument shifting
    let destroyOnHidden;
    if (typeof destroyOnHidden === "function") {
      callback = destroyOnHidden;
      destroyOnHidden = true;
    }

    if (["hidden", "destroyed"].includes(this._state)) {
      __guardFunc__(callback, f => f());
      return this;
    }

    // Add the callbacks
    if (callback) { this._hideCallbacks.add(callback); }
    if (this.options.destroyOnHidden === true) { this._hideCallbacks.add(_.bind(this.destroy, this)); }

    // make sure the callback is added before doing this check
    if (this._state === "hiding") {
      return this;
    }

    this._state = 'hiding';
    (this.$target || this.$el).trigger(this._getNamespace() + ':hide');

    if (this.options.animate && transitionend) {

      this.$el.removeClass('is-transitioning-in u-hidden'); // just in case

      // force a repaint to ensure transitioning works
      this._position();

      this.$el
        .addClass('is-transitioning-out')
        .off(`.${this._getNamespace()}`)
        .one(transitionend + "." + this._getNamespace(), _.bind( function() {
          this.$el.removeClass('is-transitioning-out');
          return this._onHidden();
        }
        , this));
    } else {
      this._onHidden(callback);
    }
    return this;
  }

  destroy() {
    if (!["hidden", "destroyed"].includes(this._state)) { this._onHidden(); }
    this._state = "destroyed";
    this._showCallbacks = null;
    this._hideCallbacks = null;
    __guard__(this.$el, x => x.remove());
    return super.destroy(...arguments);
  }

  _initialize() {
    this._state = 'hidden';
    this._showCallbacks = $.Callbacks();
    this._hideCallbacks = $.Callbacks();
    return __guard__(this.$target, x => x.on("gears:showablewidget:remove", _.bind(this.destroy, this)));
  }

  _onShown() {
    this._state = 'shown';
    (this.$target || this.$el).trigger(this._getNamespace() + ':shown');
    __guard__(this._showCallbacks, x => x.fire().empty());
    return __guard__(this._hideCallbacks, x1 => x1.empty());
  }

  _onHidden() {
    this.$el.addClass("u-hidden");
    this._state = 'hidden';
    __guard__((this.$target || this.$el), x => x.trigger(this._getNamespace() + ':hidden'));
    if (this.options.removeOnHidden) {
      this.$el.remove();
      this.$el = null;
    }
    __guard__(this._hideCallbacks, x1 => x1.fire().empty());
    return __guard__(this._showCallbacks, x2 => x2.empty());
  }

  // if overriding and not calling super, make sure your
  // position method forces a repaint so transitions work
  _position() {
    return this.$el[0].offsetHeight; // force a repaint
  }
}


ShowableWidget.defaults = {
  animate: true,
  destroyOnHidden: false,
  removeOnHidden: true
};

ShowableWidget.namespace = 'gears:showablewidget';

export default ShowableWidget;

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
function __guardFunc__(func, transform) {
  return typeof func === 'function' ? transform(func) : undefined;
}
function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
