// @ts-nocheck
import BaseView from 'base_view';
import routingNumberValidator from 'views/contributions/account_info/validation/rails.validations.routing_number';
import ClientSideValidations from 'rails.validations';

export default class NewView extends BaseView {
  constructor() {
    super();
    ClientSideValidations.validators.local.routing_number = routingNumberValidator;
    this.markPageLoaded();
  }
}
