// @ts-nocheck
import $ from 'jquery';

const SELECTOR_PREFIX = '.js-areas-sidebar';

const SELECTORS = {
  SIDEBAR: `${SELECTOR_PREFIX}__sidebar`,
  LIST: `${SELECTOR_PREFIX}__list`,
  LINK: `${SELECTOR_PREFIX}__link`,
  FOOTER: `${SELECTOR_PREFIX}__footer`,
};

export default class AreasSidebar {
  constructor(element = window) {
    this.window = $(element);
    this.setUpItemExpandability();
    new ScrollHandler(this.window);
  }

  setUpItemExpandability() {
    return $(SELECTORS.LINK).click((event) => $(event.target).parents('li').find('ul').toggle());
  }
}

class ScrollHandler {
  constructor(window) {
    this.window = window;
    this.sidebar = $(SELECTORS.SIDEBAR);
    this.list = $(SELECTORS.LIST);
    this.footer = $(SELECTORS.FOOTER);
    this.originalPosition = this.list.css('position');
    this.originalTop = this.list.css('top');

    // Trigger immediately in case the page is already scrolled
    this.window
      .on('scroll resize', () => this.positionList())
      .scroll()
      .resize();
  }

  positionList() {
    this.listHeight = this.list.height();
    this.sidebarTop = this.sidebar.offset().top;
    this.footerTop = this.footer.offset().top;
    // yes, this is a hack
    this.screenTop = this.window[0] === window ? this.window.scrollTop() : this.pageTop();

    let position;
    let top;
    if (this.listIsOverlappingFooter()) {
      [position, top] = this.aboveFooter();
    } else if (this.sidebarTopIsOffscreen()) {
      [position, top] = this.topOfScreen();
    } else {
      [position, top] = [this.originalPosition, this.originalTop];
    }

    this.list.css({ position, top });
  }

  sidebarTopIsOffscreen() {
    return this.sidebarTop < this.screenTop;
  }

  listIsOverlappingFooter() {
    // Don't consider the location of the list. When the list is too low, it
    // moves up. On the next check it's not too low, so it moves back down.
    // When you scroll, it strobes. Instead, check whether the list is
    // overflowing the space between the footer and the top of the screen. -jf
    return this.listHeight > this.footerTop - this.screenTop;
  }

  aboveFooter() {
    // The extra 10px is a guess that seems to look good
    return ['fixed', this.footerTop - this.screenTop - this.listHeight - 10];
  }

  topOfScreen() {
    return ['fixed', this.pageTop()];
  }

  pageTop() {
    return (this.window.offset() && this.window.offset().top) || 0;
  }
}
