var moduleExport;

var el = document.createElement('div')
  , eventMap = {
  'transition'       : 'transitionend',
  'WebkitTransition' : 'webkitTransitionEnd',
  'MozTransition'    : 'transitionend',
  'OTransition'      : 'oTransitionEnd otransitionend'
};

for (var key in eventMap) {
  if (eventMap.hasOwnProperty(key) && el.style[key] != null) {

    // return the transition end event in the map
    moduleExport = eventMap[key];
  }
}

export default moduleExport || null;
