// @ts-nocheck
// Client side validations for cc number

ClientSideValidations.validators.local.credit_card = function (element, options) {
  var sum = 0;
  var even = false;
  var number = element.val() + '';
  for (var i = number.length - 1; i >= 0; i--) {
    if (even) {
      var doubled = number[i] * 2 + '';
      sum += doubled.length == 2 ? parseInt(doubled[0]) + parseInt(doubled[1]) : parseInt(doubled[0]);
    } else {
      sum += parseInt(number[i]);
    }
    even = !even;
  }

  if (sum % 10 != 0) {
    return options.message;
  }

  var acceptedCardPatterns = /^(4|5[1-5]|2[2-7]|6011|62|30|36|38|39|35)/;

  if (!acceptedCardPatterns.test(number)) {
    return 'invalid card type';
  }
};

(function ($) {
  // auto detect card type below
  var patterns = {
    visa: /^4/,
    mastercard: /^(5[1-5]|2[2-7])/,
    amex: /^(34|37)/,
    discover: /^6011/,
    unionpay: /^62/,
    diners: /^(30|36|38|39)/,
    jcb: /^35/,
  };

  // Set this to true if you discover that HTML5 input events are supported
  var isHTML5InputEventSupported = false;

  function CCType(el, options) {
    this.options = $.extend(
      {},
      {
        onTypeChange: this._defaultTypeChange,
      },
      options,
    );

    this.el = el;
    this._ccType = undefined;

    this._analyzeCCNumber = this._analyzeCCNumber.bind(this);
    this._cardTypeForCcNumber = this._cardTypeForCcNumber.bind(this);
    this._onInput = this._onInput.bind(this);
    this._onKeydown = this._onKeydown.bind(this);
    this._defaultTypeChange = this._defaultTypeChange.bind(this);

    this.el.on('input.ccType', this._onInput);
    this.el.on('keydown.ccType', this._onKeydown);
  }

  CCType.prototype._analyzeCCNumber = function () {
    var ccNumber = this.el.val();
    // If a type is not set, check against all patterns
    // If a type is already set, check to see if it no longer matches that pattern
    if (this._ccType == null || !patterns[this._ccType].test(ccNumber)) {
      this._ccType = this._cardTypeForCcNumber(ccNumber);
      this.options.onTypeChange.call(this, this._ccType);
    }
  };

  CCType.prototype._cardTypeForCcNumber = function (ccNumber) {
    for (var card in patterns) {
      if (patterns[card] && patterns[card].test(ccNumber)) {
        return card;
      }
    }
  };

  // HTML5 event to input changes, use this if the browser supports it
  CCType.prototype._onInput = function (e) {
    isHTML5InputEventSupported = true;
    this._analyzeCCNumber();
  };

  //	Fallback for when the browser doesn't support HTML5 input events
  CCType.prototype._onKeydown = function (e) {
    if (isHTML5InputEventSupported) {
      this.el.off('keydown.ccType', this._onKeydown);
    } else {
      setTimeout(this._analyzeCCNumber, 1);
    }
  };

  CCType.prototype._defaultTypeChange = function (type) {
    if (type) {
      $('#cc-icon-list img').addClass('cc-icon--faded');
      $('#cc-icon-' + type).removeClass('cc-icon--faded');
      this.el.closest('form').find('#payment_token_card_type').val(type);
    } else {
      $('#cc-icon-list img').removeClass('cc-icon--faded');
      this.el.closest('form').find('#payment_token_card_type').val('');
    }
  };

  $.fn.ccType = function (options) {
    options = options || {};

    // if a string method name was passed as the first argument, merge it into the options object
    if (typeof options === 'string') {
      options = $.extend({}, arguments[1] || {}, { method: options });
    }

    return this.each(function () {
      var ccType;
      if ($(this).data('ccType') instanceof CCType) {
        ccType = $(this).data('ccType');
        $.extend(ccType.options, options);
      } else {
        ccType = new CCType($(this), options);
        $(this).data('ccType', ccType);
      }

      // call the method if it's public
      if (options.method && options.method.substr(0, 1) !== '_') {
        return __guardMethod__(ccType, options.method, function (o, m) {
          return o[m]();
        });
      }
    });
  };
})(jQuery);

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}

export {};
