// @ts-nocheck
import $ from 'jquery';

export default class BaseView {
  constructor() {
    this.dismissClosedBanners();
    this.addListenerToDismissibleBanners();
  }

  markPageLoaded() {
    $('body').append('<span class="js-page-loaded" style="display: none;"></span>');
    this.loaded = true;
  }

  dismissClosedBanners() {
    for (let key of Object.keys(window.sessionStorage)) {
      if (key.startsWith('alert-dismissible')) {
        $(`#${key}`).remove();
      }
    }
  }

  addListenerToDismissibleBanners() {
    $('.alert-dismissible > .btn-close').on('click', (e) => {
      const alertKey = $(e.target).closest('.alert-dismissible').attr('id');
      window.sessionStorage.setItem(alertKey, '1');
    });
  }
}
