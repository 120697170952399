import _ from 'lodash';
import $ from 'jquery';
import ShowableWidget from 'gears/ui/showable-widget/component';

// Public Class Methods

class Alert extends ShowableWidget {

  show() {
    throw new Error("the show method is not supported for Alerts");
  }

  _initialize() {
    super._initialize(...arguments);
    this._state = "shown";
    if (this.$target) {
      this.$el = this.$target;
      this._renderNew();
    } else {
      this._renderNew();
      this.$target = this.$el.data(this._getNamespace(), this);
    }
    return this;
  }

  _update() {
    this._renderUpdate();
    return this;
  }

  _renderNew() {
    if (!this.$el) { this.$el = $(document.createElement(this.options.tag)); }
    let classes = [Alert.className];
    if ("body" in this.options) {
      this.$el.empty().append(this.options.body);
    }
    if (typeof this.options.type === "string") {
      classes.push(`${Alert.className}--${this.options.type}`);
    }
    if (this.options.icon === true) {
      classes.push(`${Alert.className}--with-icon`);
    }
    this.$el.addClass(classes.join(" "));
    this._configureHideButton();
    this._configureAutoHide();

    // set the hide click event
    let self = this;
    return self.$el.on(`click.${this._getNamespace()}`, `.js-${Alert.className}-hide`, function(e) {
      e.preventDefault();
      return self.hide();
    });
  }

  _renderUpdate() {
    if ("body" in this.changedOptions) {
      this.$el.empty().append(this.options.body);
    }
    if ("type" in this.changedOptions) {
      this.$el.removeClass(`${Alert.className}--${this.previousOptions.type}`);
      this.$el.addClass(`${Alert.className}--${this.options.type}`);
    }
    if ("icon" in this.changedOptions) {
      if (this.options.icon === true) {
        this.$el.addClass(`${Alert.className}--with-icon`);
      } else {
        this.$el.removeClass(`${Alert.className}--with-icon`);
      }
    }
    this._configureHideButton();
    return this._configureAutoHide();
  }

  _configureHideButton() {
    if (this.options.hideable === true) {
      this.$el.addClass(`${Alert.className}--hideable`);
      let $hideButton = this.$el.find(`.js-${Alert.className}-hide`);
      if ($hideButton.length === 0) {
        $(`<a href=\"#\" class=\"js-${Alert.className}-hide ${Alert.className}__hide\" />`).prependTo(this.$el);
      }
    }
    if (this.options.hideable === false) {
      this.$el.removeClass(`${Alert.className}--hideable`);
      return this.$el.find(`.js-${Alert.className}-hide`).remove();
    }
  }

  _configureAutoHide() {
    clearTimeout(this._timeout);
    if (typeof this.options.autoHide === 'number') {
      return this._timeout = setTimeout(_.bind(this.hide, this), this.options.autoHide);
    }
  }
}

Alert.className = "alert";

Alert.namespace = "gears:alert";

Alert.defaults = {
  tag: "p",
  animate: true,
  hideable: true,
  removeOnHidden: true,
  destroyOnHidden: true
  // icon: (optional)
  // body: (optional)
  // autoHide: (optional)
  // type: (optional)
};

export default Alert;
