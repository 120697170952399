// @ts-nocheck
import RoutingNumberValidator from 'views/contributions/account_info/validation/routing_number_validator';

export default function ($element) {
  let _routingNumber;

  try {
    _routingNumber = $element.val();
  } catch (err) {
    _routingNumber = $element;
  }
  return new RoutingNumberValidator(_routingNumber).validate();
}
