// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';
import ClientSideValidations from 'rails.validations';
import 'views/contributions/account_info/validation/card_validator';
import JSEncrypt from 'jsencrypt';

export default class CcAccountInfoView extends BaseView {
  constructor() {
    super();
    $('#payment_token_card_number').ccType();
    ClientSideValidations.callbacks.form.fail = () => {
      const firstValidationError = $('.row.is-invalid')[0];
      firstValidationError && firstValidationError.scrollIntoView();
    };

    const submitHandler = (event) => {
      const data = $('#card-submit')[0].dataset;
      const publicKey = data.publicKey;
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      const cardNumber = $('#payment_token_card_number').get(0).value;
      $('#payment_token_card_number').prop('name', '');
      const encryptedCardNumber = encryptor.encrypt(cardNumber);
      const cardCvv = $('#payment_token_card_cvv').get(0).value;
      $('#payment_token_card_cvv').prop('name', '');
      const encryptedCardCvv = encryptor.encrypt(cardCvv);
      $('#payment_token_encrypted_card_number').get(0).value = encryptedCardNumber;
      $('#payment_token_encrypted_card_cvv').get(0).value = encryptedCardCvv;
      $('#payment_token_card_last4').get(0).value = cardNumber?.slice(-4);
      $('#payment_token_bin').get(0).value = cardNumber?.slice(0, 6);
    };

    $('#new_payment_token').on('submit', submitHandler);
    this.markPageLoaded();
  }
}
