// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';
import AreasSidebar from 'components/areas_sidebar';
import FileDownloadLinkListener from 'components/file_download_link_listener';
import 'magnific-popup';

export default class PublishedInspectionShowView extends BaseView {
  constructor() {
    super();
    this.setUpLightbox();
    this.fileDownloadLinkSetUp();
    new AreasSidebar('.js-layout-content');
    this.markPageLoaded();
  }

  fileDownloadLinkSetUp() {
    const triggers = ['.js-inspection-download-link'];
    const fileDownloadLinkListener = new FileDownloadLinkListener('body', triggers);
  }

  setUpLightbox() {
    $('.js-inspection-details').magnificPopup({
      delegate: '.item__image a',
      type: 'image',
      gallery: { enabled: true },
    });
  }
}
