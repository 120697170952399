// @ts-nocheck
import _ from 'lodash';
import $ from 'jquery';
import ShowableWidget from 'gears/ui/showable-widget/component';
import Alert from 'gears/ui/alert/component';

// Public Class Methods

class GearsAlertBootstrap extends Alert {
  _renderNew() {
    if (!this.$el) {
      this.$el = $(document.createElement(this.options.tag));
    }
    let classes = [Alert.className];
    if ('body' in this.options) {
      this.$el.empty().append(this.options.body);
    }
    if (typeof this.options.type === 'string') {
      classes.push(`${Alert.className}-${this.options.type}`);
    }
    if (this.options.icon) {
      classes.push(`${Alert.className}--with-icon`);
    }
    this.$el.addClass(classes.join(' '));
    this._configureHideButton();
    this._configureAutoHide();
  }

  _renderUpdate() {
    if ('body' in this.changedOptions) {
      this.$el.empty().append(this.options.body);
    }
    if ('type' in this.changedOptions) {
      this.$el.removeClass(`${Alert.className}-${this.previousOptions.type}`);
      this.$el.addClass(`${Alert.className}-${this.options.type}`);
    }
    if ('icon' in this.changedOptions) {
      if (this.options.icon) {
        this.$el.addClass(`${Alert.className}--with-icon`);
      } else {
        this.$el.removeClass(`${Alert.className}--with-icon`);
      }
    }
    this._configureHideButton();
    return this._configureAutoHide();
  }

  _configureHideButton() {
    if (this.options.hideable) {
      this.$el.addClass('alert-dismissible fade show');
      this.$el.append(
        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true">&times;</span>' +
          '</button>',
      );
    } else {
      this.$el.removeClass('alert-dismissible fade show');
      return this.$el.find('button.btn-close').remove();
    }
  }
}

GearsAlertBootstrap.className = 'alert';

GearsAlertBootstrap.defaults = {
  tag: 'p',
  animate: true,
  hideable: true,
  removeOnHidden: true,
  destroyOnHidden: true,
};

export default GearsAlertBootstrap;
