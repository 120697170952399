// @ts-nocheck
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= append_javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '@babel/polyfill';
import 'es6-promise/auto';
import 'whatwg-fetch';
import 'raf/polyfill';

import $ from 'jquery';
import 'extensions/rails.validations.actionView';

import BaseView from 'base_view';

import ContributionsAccountInfoAchView from 'views/contributions/account_info/ach_view';
import ContributionsAccountInfoCCView from 'views/contributions/account_info/cc_view';
import ContributionsNewView from 'views/contributions/new_view';
import ContributionsReviewView from 'views/contributions/review_view';
import StatementsIndexView from 'views/statements/index_view';
import ECheckAccountDetailsNewView from 'views/e_check_account_details/new_view';
import PublishedInspectionsIndexView from 'views/published_inspections/index_view';
import PublishedInspectionsShowView from 'views/published_inspections/show_view';
import RegistrationsNewView from 'views/devise/registrations/new_view';
import TenNinetyNinesEditView from 'views/ten_ninety_nines/edit_view';
import TwoFactorView from 'views/devise/two_factor';

const PAGES = {
  ContributionsAccountInfoAchView,
  ContributionsAccountInfoCCView,
  ContributionsNewView,
  ContributionsReviewView,
  StatementsIndexView,
  ECheckAccountDetailsNewView,
  PublishedInspectionsIndexView,
  PublishedInspectionsShowView,
  RegistrationsNewView,
  TenNinetyNinesEditView,
  TwoFactorView,
};

$(document).on('click', '.is-disabled', (event) => event.preventDefault());

$('.js-toggle-menu, #overlay').on('click', () => {
  $('#overlay').toggleClass('d-none').toggleClass('show');
  $('#main').toggleClass('sidebar-open');
});

function loadPageModule() {
  $(document).ready(() => {
    if (typeof jsPageModule !== 'undefined') {
      const ViewClass = PAGES[jsPageModule];
      new ViewClass();
    } else {
      const page = new BaseView();
      page.markPageLoaded();
    }
  });
}

$(function enableBootstrapTooltips() {
  const selector = $('[data-bs-toggle="tooltip"]');

  if (selector.length > 0) selector.tooltip();
});

$(function enableBootstrapPopovers() {
  const selector = $('[data-bs-toggle="popover"]');

  if (selector.length > 0) selector.popover();
});

loadPageModule();
