// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';

export default class ReviewView extends BaseView {
  constructor() {
    super();

    const $confirmation = $('.js-contribution-confirmation');

    $confirmation.on('click', (event) => {
      event.preventDefault();

      $('.js-actions').hide();
      $('.js-contribution-review-spinner').show();

      $.post($confirmation.attr('href')).done((data) => {
        this.getProgress(data.remoteURL);
      });
    });

    if ($confirmation.hasClass('js-auto-submit')) {
      $confirmation.click();
    }

    this.markPageLoaded();
  }

  getProgress = (url) => {
    $.post(url)
      .done((response) => {
        switch (response.status) {
          case 'success':
            window.location.href = response.to;
            break;
          case 'failed':
            window.location.href = response.to;
            break;
          case 'pending':
            setTimeout(this.getProgress, 1000, url);
            break;
        }
      })
      .fail(() => {
        setTimeout(this.getProgress, 500, url);
      });
  };
}
