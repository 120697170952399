// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';

const RETURN_KEY_CODE = 13;

export default class NewView extends BaseView {
  constructor() {
    super();
    this._emailInput = $('.js-email');
    this._signUpButton = $('.js-sign-up-button');

    this._emailInput.on('input', () => this._updateSignUpButton());
    this._emailInput.on('keypress keyup', (event) => this._suppressReturnKeyFormSubmit(event));

    this._updateSignUpButton();
    this.markPageLoaded();
  }

  _updateSignUpButton() {
    this._signUpButton.prop('disabled', this._emailInput.val().trim() === '');
  }

  _suppressReturnKeyFormSubmit(event) {
    if (event.which === RETURN_KEY_CODE && this._signUpButton.prop('disabled')) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
