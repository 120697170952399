// @ts-nocheck
import $ from 'jquery';
import { AsYouTypeFormatter, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import BaseView from 'base_view';

export default class TwoFactor extends BaseView {
  constructor() {
    super();

    $(document).ready(() => {
      const form = $('#two-factor-verification');
      const title1 = $('#two-factor-verification-title1');
      const title2 = $('#two-factor-verification-title2');
      const step1 = $('#two-factor-verification-step1');
      const step2 = $('#two-factor-verification-step2');
      const phoneNumberField = $('#user_phone_number_field');
      const sentPhoneNumber = $('#user_phone_number');
      const email2fa = $('#user_email_2fa');
      const email = $('#user_email');
      const send = $('#send_verification_code');
      const resend = $('#resend_verification_code');
      const back = $('#two-factor-verification-back');
      const verificationCodeField = $('#verification_code');
      const notifications = $('#notifications');
      const countryDropdown = $('#country-dropdown');
      let countryCode = countryDropdown.val();

      const phoneUtil = PhoneNumberUtil.getInstance();

      this.formatAsYouTypePhoneNumber = () => {
        const oldInput = phoneNumberField.val();
        const strippedInput = oldInput.replace(/[^0-9]/g, '');

        const formatter = new AsYouTypeFormatter(countryCode);
        strippedInput.split('').forEach((digit) => {
          formatter.inputDigit(digit);
        });
        const formatIncompleteNumber = formatter.currentOutput_;

        if (formatIncompleteNumber !== oldInput) {
          phoneNumberField.val(formatIncompleteNumber);
        }
      };

      form.devise2fa({
        validate: function validate() {
          if (email2fa.val() === 'true') {
            return true;
          }
          if (countryCode && phoneNumberField && phoneNumberField.val()) {
            // parseAndKeepRawInput will throw an error in some invalid cases
            // such as if the number passed in is too short or too long
            // we can catch that error silently and invalidate the user's submission
            try {
              const countryCodeNumber = phoneUtil
                .parseAndKeepRawInput(phoneNumberField.val(), countryCode)
                .getCountryCode();
              if (countryCodeNumber === 1 && phoneNumberField.val().startsWith(1)) {
                return false;
              }
              const enteredPhoneNumber = '+' + countryCodeNumber + phoneNumberField.val();

              const newPhoneNumber = phoneUtil.parseAndKeepRawInput(enteredPhoneNumber, countryCode);
              if (newPhoneNumber && phoneUtil.isPossibleNumber(newPhoneNumber)) {
                sentPhoneNumber.val(phoneUtil.format(newPhoneNumber, PhoneNumberFormat.E164));
                return true;
              }
            } catch {
              return false;
            }
          } else if (sentPhoneNumber && sentPhoneNumber.val()) {
            return true;
          }
          return false;
        },
        success: () => {
          send.val('Send Verification Code').removeAttr('disabled');
          step1.css('display', 'none');
          title1.css('display', 'none');
          step2.css('display', 'block');
          title2.css('display', 'block');
          if (email2fa.val() === 'true') {
            notifications.html(
              '<div class="alert alert-success" id="tfa-success">' +
                `A verification code was sent to ${email.val()}.` +
                '</div>',
            );
          } else {
            const lastFourDigits = sentPhoneNumber.val().slice(-4);
            notifications.html(
              '<div class="alert alert-success" id="tfa-success">' +
                `A verification code was sent to a number ending in ${lastFourDigits}.` +
                '</div>',
            );
          }

          verificationCodeField.focus();
        },
        error: (jqXHR) => {
          send.val('Send Verification Code').removeAttr('disabled');
          const twilioResponse = jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.status;
          form.trigger('invalid.devise2fa', twilioResponse);
        },
      });

      back.on('click', () => {
        $('.alert').remove();
        step1.css('display', 'block');
        title1.css('display', 'block');
        step2.css('display', 'none');
        title2.css('display', 'none');
      });

      send.on('click', (event) => {
        $('.alert').remove();
        event.preventDefault();
        form.devise2fa('sendCode');
      });

      resend.on('click', (event) => {
        $('.alert').remove();
        event.preventDefault();
        form.devise2fa('sendCode');
      });

      // allow pressing enter while in the phone number field to submit
      phoneNumberField.on('keypress', (event) => {
        const keycode = event.keyCode ? event.keyCode : event.which;
        if (keycode === 13) {
          send.click();
          event.preventDefault();
        }
      });

      phoneNumberField.on('keyup', (event) => {
        const keycode = event.keyCode ? event.keyCode : event.which;
        if (keycode !== 13) {
          this.formatAsYouTypePhoneNumber();
        }
      });

      phoneNumberField.change(() => {
        this.formatAsYouTypePhoneNumber();
      });

      verificationCodeField.on('keypress', (event) => {
        const keycode = event.keyCode ? event.keyCode : event.which;
        if (keycode === 13) {
          form.submit();
          event.preventDefault();
        }
      });

      countryDropdown.on('change', (event) => {
        countryCode = countryDropdown.val();
      });

      form.on('invalid.devise2fa', (_event, message) => {
        message = message || 'Please enter a valid phone number.';
        notifications.html('<div class="alert alert-danger" id="tfa-error">' + message + '</div>');

        form.find('#user_phone_number_field').addClass('is-invalid').closest('.form__row').addClass('is-invalid');
      });

      form.on('valid.devise2fa', () => {
        if (send.length !== 0) {
          send.val('Please Wait...').attr('disabled', 'disabled');
          $('.phone-number-feedback').removeClass('show-feedback');
          form
            .find('#user_phone_number_field')
            .removeClass('is-invalid')
            .closest('.form__row')
            .removeClass('is-invalid');
        }
      });
    });

    this.markPageLoaded();
  }
}
