// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';

export default class PublishedInspectionsIndexView extends BaseView {
  constructor() {
    super();
    $(document).on('click', 'tr[data-href]', (event) => {
      window.location = $(event.currentTarget).data('href');
    });
    this.markPageLoaded();
  }
}
