// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';
import { setUpCheckboxAndDropdown } from 'views/ten_ninety_nines/form';
import GearsAlertBootstrap from 'components/gears_alert_bootstrap';
import FileDownloadLinkListener from 'components/file_download_link_listener';

export default class TenNinetyNinesEditView extends BaseView {
  constructor() {
    super();
    this.fileDownloadLinkSetUp();
    this.setUpE1099SettingsForm();
    this.markPageLoaded();
  }

  fileDownloadLinkSetUp() {
    const triggers = ['.js-ten-ninety-nine-download-link'];
    const fileDownloadLinkListener = new FileDownloadLinkListener('body', triggers);
  }

  setUpE1099SettingsForm() {
    const alert = (options) => {
      const $flashMessage = $('.js-page-instructions');
      $flashMessage.html(new GearsAlertBootstrap(options).$el);
    };

    $(document).ajaxError((e, xhr) => {
      const data = xhr.responseJSON;

      if (xhr.status === 401) {
        window.location.reload();
      } else if (xhr.status === 422 && data && data.body) {
        alert({ type: 'danger', body: data.body });
      }
    });

    const tenNinetyNineForm = setUpCheckboxAndDropdown();
    tenNinetyNineForm.setupPreferenceDropdownForReady();

    $('.js-ten-ninety-nine-consent-checkbox').on('change', (e) => {
      const consentToReceiveElectronic1099Only = e.currentTarget.checked;

      tenNinetyNineForm.consentChange(
        tenNinetyNineForm._defaultDropdownValue,
        tenNinetyNineForm._defaultCheckedValue,
        consentToReceiveElectronic1099Only,
      );
    });

    $('.js-ten-ninety-nines-settings-form').on('ajax:complete', (e, xhr) => {
      const status = xhr.status;
      const data = xhr.responseJSON;

      if (status === 200 && data && data.body) {
        alert({ type: 'success', body: data.body });
      } else {
        alert({ type: 'danger', body: data.body });
      }
    });
  }
}
